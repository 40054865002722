import debounce from 'debounce';
import { equals } from 'ramda';

// import type { LocationQueryValue } from 'vue-router';
import type { SearchOpenType } from '@/stores/search';

import type { SearchParams } from '@/types';

// utils
import dayjs from '@/utils/day';
import { getQueryValue, getParamsValue } from '@/utils/routing';

// fixtures
import { searchUiAges } from '@/locales/fixtures';

export default function useSearch({ contentTransition }: { contentTransition?: Ref<string> }) {
  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();
  const localePath = useLocalePath();
  const route = useRoute();
  const routeStore = useRouteStore();
  const searchStore = useSearchStore();
  const { $gettext } = useGettext();
  const { getRegionUrl, getCityUrl, getCountryOrFederalStateUrl } = useLocationUrls();
  const { locale } = useI18n();
  const { pointerBackward, pointerForward, showSuggestions, pointer, i18nSuggestionTitle, i18nPromoCampsitesTitle }
    = useSearchSuggestions();

  /********************
   * REFS & VARS       *
   ********************/
  const clearedMapSection = ref(false);
  const dateSetOnce = ref(false);
  const highlightedResultItem = ref(null);
  const inputFocused = ref(false);
  const jumpDate = ref<Date | null>(null);
  const selectedSuggestion = ref(null);

  const ages = computed(() => {
    return searchUiAges.map((obj) => {
      const { value } = obj;
      return { ...obj, value: $gettext(value) };
    });
  });

  const hasLocation = computed(() => {
    return searchStore.suggestions.locations?.length;
  });

  const hasCampsite = computed(() => {
    return searchStore.suggestions.campsites?.length;
  });

  const isAreaSearch = computed(() => {
    return !!route.query.area && !clearedMapSection.value;
  });

  const searchHasChanged = computed(() => {
    const validKeys = [
      'adults',
      'children',
      'q',
      'flex',
      'discounts',
      'from',
      'until',
      'acco_type',
      'only_availabilities',
    ];

    const baseRouteName = getRouteBaseName(route);
    const isCitySearch = baseRouteName === RBN_CITY_SEARCH;
    const isCountrySearch = baseRouteName === RBN_COUNTRY_SEARCH;
    const isFederalState = baseRouteName === RBN_FEDERAL_STATE_SEARCH;
    const isRegionSearch = baseRouteName === RBN_REGION_SEARCH;

    // route params
    const { query: routeQuery, params: routeParams } = route;
    const rQuery = { ...routeQuery };
    if (rQuery.adults) {
      rQuery.adults = parseInt(rQuery.adults);
    }
    if (rQuery.flex) {
      rQuery.flex = parseInt(rQuery.flex);
    }
    if (!rQuery.q) {
      if (isRegionSearch) {
        rQuery.q = routeParams.regionSlug;
      }
      if (isCountrySearch) {
        rQuery.q = routeParams.countrySlug;
      }
      if (isCitySearch) {
        rQuery.q = routeParams.citySlug;
      }
      if (isFederalState) {
        rQuery.q = routeParams.federalStateSlug;
      }
    }
    if (rQuery.q) {
      rQuery.q = rQuery.q.toLowerCase();
    }

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    Object.keys(rQuery).forEach(key => validKeys.includes(key) || delete rQuery[key]);

    // search params
    const query = { ...searchStore.searchParams };
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    Object.keys(query).forEach(key => (!query[key] || query[key] === 'all') && delete query[key]);
    if (query.q) {
      query.q = query.q.toLowerCase();
    }

    return !equals(rQuery, query);
  });

  const datePickerValues = computed(() => {
    return [searchStore.searchParams.from, searchStore.searchParams.until];
  });

  const childrenCount = computed(() => {
    if (searchStore.searchParams.children === null) {
      return 0;
    }

    if (typeof searchStore.searchParams.children === 'string') {
      return searchStore.searchParams.children.split(',').length;
    } else {
      return 0;
    }
  });

  const localChildrenCount = computed(() => {
    if (searchStore.searchParams.children === null) {
      return 0;
    }

    if (typeof searchStore.searchParams.children === 'string') {
      return searchStore.searchParams.children.split(',').length;
    } else {
      return 0;
    }
  });

  const dateTouched = computed(() => {
    return searchStore.searchTouchedDetails.includes('date');
  });

  /********************
   * WATCHER           *
   ********************/
  watch(() => searchStore.searchOpenType, onSearchOpenTypeChange, {
    immediate: false,
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function onSearchOpenTypeChange(newValue) {
    if (newValue === 'location') {
      searchStore.getSuggestions({ lang: locale.value, query: searchStore.searchParams.q || null, forceUpdate: true });
    }

    if (newValue === 'date') {
      jumpDate.value = searchStore.searchParams.from ? dayjs(searchStore.searchParams.from).toDate() : null;
    }
  }

  function getSlug(resultItem) {
    if (resultItem.slug) {
      return resultItem.slug;
    }
    return resultItem.type === 'federal_state'
      ? `${resultItem.country.slugs[locale.value]}/${resultItem.slugs[locale.value]}`
      : resultItem.slugs[locale.value];
  }

  function goToSearchRegion(currentRouteName, slug, query) {
    const route = getRegionUrl(slug, query);

    if (!route.name) {
      route.name = currentRouteName;
    }

    if (currentRouteName === RBN_SEARCH_MAP) {
      if (checkEqualSearch(route)) {
        return;
      }

      return navigateTo(localePath(route), { replace: true });
    } else {
      if (checkEqualSearch(route)) {
        return;
      }

      return navigateTo(localePath(route));
    }
  }

  function goToSearchCity(currentRouteName, slug, query) {
    const route = getCityUrl(slug, query);

    if (!route.name) {
      route.name = currentRouteName;
    }

    if (currentRouteName === RBN_SEARCH_MAP) {
      if (checkEqualSearch(route)) {
        return;
      }

      return navigateTo(localePath(route), { replace: true });
    } else {
      if (checkEqualSearch(route)) {
        return;
      }

      return navigateTo(localePath(route));
    }
  }

  function goToSearchCountryOrFederalState(currentRouteName, slug, query) {
    const route = getCountryOrFederalStateUrl(slug, query);

    if (checkEqualSearch(route)) {
      return;
    }

    if (
      (currentRouteName === RBN_SEARCH_MAP && slug.split('/').length === 1)
      || (currentRouteName === RBN_SEARCH_MAP && slug.split('/').length === 2)
    ) {
      return navigateTo(localePath(route), { replace: true });
    } else {
      return navigateTo(localePath(route));
    }
  }

  function checkEqualSearch(newRoute) {
    const { params, query } = route;

    if (query.adults) {
      query.adults = parseInt(query.adults);
    }

    if (query.flex) {
      query.flex = parseInt(query.flex);
    }

    const currentRoute = { name: getRouteBaseName(route), params, query };
    if (newRoute.params === undefined && currentRoute.params !== undefined) {
      newRoute.params = {};
    }
    return equals(currentRoute, newRoute);
  }

  function getChildAge(idx) {
    const key = parseInt(searchStore.searchParams?.children?.split(',')[idx]) || 0;
    return ages.value.find(item => item.key === key);
  }

  function onLocationInputFocus() {
    inputFocused.value = true;
    pointer.value = -1;
    showSuggestions.value = true;
  }

  const onLocationChange = debounce(function (value) {
    selectedSuggestion.value = null;

    if (searchStore.searchOpenType !== 'location') {
      searchStore.searchOpenType = 'location';
    }

    if (value.length > 2) {
      searchStore.getSuggestions({ lang: locale.value, query: value });
    }

    searchStore.setSearchParams({
      ...searchStore.searchParams,
      q: value,
    });

    // set point to -1 which means focus is on input
    pointer.value = -1;

    if (value !== '' && value.length > 2) {
      showSuggestions.value = true;
    }
  }, 200);

  function onLocationClear() {
    searchStore.setSearchParams({
      ...searchStore.searchParams,
      q: '',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  async function onLocationSelected(result, emit?: Function) {
    if (!result && highlightedResultItem.value) {
      result = highlightedResultItem.value;
    }

    if (!result && searchStore.searchParams.q?.length > 0) {
      const locationResult = await searchStore.getLocationSearchResults({ q: searchStore.searchParams.q, lang: locale.value });
      if (locationResult) {
        return onLocationSelected(locationResult);
      }
    }

    if (result) {
      selectedSuggestion.value = result;
      searchStore.setSearchParams({ ...searchStore.searchParams, q: result.name });
    }

    if (!searchStore.searchTouched) {
      openOpenType('date');
    } else {
      if (emit) {
        emit('close');
      }
      onSearchSubmit();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  function onLocationSave(emit?: Function) {
    if (!searchStore.searchTouched) {
      // TOD0: check
      if (contentTransition?.value) {
        contentTransition.value = 'slide-next';
      }
      openOpenType('date');
    } else {
      if (emit) {
        emit('close');
      }
      onSearchSubmit();
    }
  }

  function onDateChange(value) {
    if (!value) {
      return;
    }
    const start = value[0] ? dayjs(value[0]).format('YYYY-MM-DD') : null;
    const end = value[1] ? dayjs(value[1]).format('YYYY-MM-DD') : null;

    searchStore.setSearchParams({
      ...searchStore.searchParams,
      from: start,
      until: end,
    });
  }

  function onFlexChange(value, delta) {
    searchStore.setSearchParams({
      ...searchStore.searchParams,
      flex: value ? delta : null,
    });
  }

  function onCampingCardChange(value) {
    let discounts = [...searchStore.searchParams.discounts];
    if (value) {
      if (!searchStore.searchParams.discounts.includes(DISCOUNT_TYPE_CAMPING_CARD)) {
        discounts.push(DISCOUNT_TYPE_CAMPING_CARD);
      }
    } else {
      discounts = searchStore.searchParams.discounts.filter(d => d !== DISCOUNT_TYPE_CAMPING_CARD);
    }

    searchStore.setSearchParams({
      ...searchStore.searchParams,
      discounts,
    });
  }

  function onDateClear() {
    searchStore.setSearchParams({
      ...searchStore.searchParams,
      from: null,
      until: null,
      flex: null,
      discounts: null,
    });
  }

  function onDateSave(isMobile = false) {
    if (searchStore.searchParams.from && searchStore.searchParams.until) {
      if (dateSetOnce.value && !isMobile) {
        openOpenType(null);
      } else {
        openOpenType('accommodation');
        dateSetOnce.value = true;
      }
    } else {
      openOpenType('accommodation');
    }
  }

  function onAccommodationChange(value, type) {
    let newValue;

    if (value) {
      if (searchStore.searchParams.acco_type === type) {
        newValue = null;
      }
      if (searchStore.searchParams.acco_type === null || searchStore.searchParams.acco_type === 'all') {
        newValue = type;
      } else {
        newValue = 'all';
      }
    } else if (searchStore.searchParams.acco_type === 'all') {
      newValue = type === 'acco' ? 'pitch' : 'acco';
    } else {
      newValue = null;
    }

    searchStore.setSearchParams({
      ...searchStore.searchParams,
      acco_type: newValue,
    });
  }

  function onAccommodationSave() {
    openOpenType('traveller');
  }

  function onPersonCountChange(value) {
    if (value.person.type === 'adults') {
      searchStore.setSearchParams({
        ...searchStore.searchParams,
        adults: value.amount,
      });
    }

    if (value.person.type === 'children') {
      let newValue;

      if (searchStore.searchParams.children === null) {
        newValue = '0';
      } else {
        // if new amount is 0

        if (value.amount === 0) {
          newValue = null;
        } else if (value.amount < localChildrenCount.value) { // count decrease
          const tempChildren = searchStore.searchParams.children.split(',');
          tempChildren.pop();
          newValue = tempChildren.join(',');
        } else if (value.amount > localChildrenCount.value) { // count increase
          newValue = `${searchStore.searchParams.children},0`;
        }
      }

      searchStore.setSearchParams({
        ...searchStore.searchParams,
        children: newValue,
      });
    }
  }

  function onChildAgeChange(value, index) {
    const childrenAges = searchStore.searchParams.children.split(',');
    childrenAges[index] = value;

    searchStore.setSearchParams({
      ...searchStore.searchParams,
      children: childrenAges.join(','),
    });
  }

  function clearInput(inputs) {
    inputs.forEach((value) => {
      if (value.q === '') {
        if (isAreaSearch.value) {
          clearedMapSection.value = true;
        }
        // value.region = null;
        // value.federal_state = null;
        // value.country = null;
        // value.city = null;
        // value.area = null;
      }

      if (value.q || value.q === '') {
        selectedSuggestion.value = null;
      }

      if (value.campingcard === null) {
        value.discounts = searchStore.searchParams.discounts.filter(d => d !== DISCOUNT_TYPE_CAMPING_CARD);
      }

      delete value.area;

      searchStore.setSearchParams({
        ...searchStore.searchParams,
        ...value,
      });
    });
  }

  function openOpenType(type: SearchOpenType) {
    searchStore.searchOpenType = type;

    if (type === 'date' && !dateTouched.value) {
      searchStore.setSearchTouchedDetails('date');
    }
  }

  function closeOpenType(type?: string) {
    if (!searchStore.searchTouched) {
      searchStore.searchTouched = true;
    }

    if (!type) {
      searchStore.searchOpenType = null;
      return;
    }

    if (type && searchStore.searchOpenType === type) {
      searchStore.searchOpenType = null;
    }
  }

  function onResultHighlightChange(item) {
    highlightedResultItem.value = item;
  }

  function onPressedEnter() {
    if (searchStore.searchOpenType !== 'location') {
      return;
    }

    if (pointer.value > -1 && highlightedResultItem.value) {
      searchStore.setSearchParams({ ...searchStore.searchParams, q: highlightedResultItem.value.name });
    }
    openOpenType('date');
  }

  // function onSearchParamsChange(searchParams) {
  //   searchStore.setSearchParams(searchParams);
  // }

  function onSave(openType: string | null) {
    switch (openType) {
      case 'location':
        onLocationSave();
        break;
      case 'date':
        onDateSave(true);
        break;
      case 'accommodation':
        onAccommodationSave();
        break;
    }
  }

  function mergeSearchQueries<T, U extends SearchParams>(routeQuery: T, searchParams: U): T & U {
    return { ...routeQuery, ...searchParams };
  }

  function onSearchSubmit() {
    searchStore.searchCollapsed = false;
    if (!searchStore.searchTouched) {
      searchStore.searchTouched = true;
    }

    closeOpenType();

    const { query, params: routeParams } = route;
    const currentQuery = { ...query };
    const currentRouteName = getRouteBaseName(route);

    // is CPDP
    if (currentRouteName === RBN_CAMPSITE_DETAIL) {
      const newQuery = mergeSearchQueries(currentQuery, searchStore.searchParams);

      if (newQuery.offset) {
        delete newQuery.offset;
      }

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      Object.keys(newQuery).forEach(key => (!newQuery[key] || newQuery[key] === 'all' || (Array.isArray(newQuery[key]) && !newQuery[key].length)) && delete newQuery[key]);

      // for comparison only
      if (currentQuery.adults) {
        const value = getQueryValue(currentQuery.adults);
        currentQuery.adults = value ? parseInt(getQueryValue(currentQuery.adults)) : currentQuery.adults;
      }

      // for comparison only
      if (currentQuery.flex) {
        const value = getQueryValue(currentQuery.flex);
        currentQuery.flex = value ? parseInt(value) : currentQuery.flex;
      }

      if (newQuery.from && !newQuery.until) {
        newQuery.until = dayjs(newQuery.from).add(1, 'day').format('YYYY-MM-DD');
        searchStore.setSearchParams({
          ...searchStore.searchParams,
          until: newQuery.until,
        });
      }

      if (equals(currentQuery, newQuery)) {
        return;
      }

      return navigateTo(localePath({ name: RBN_CAMPSITE_DETAIL, params: routeParams, query: newQuery }));
    }

    routeStore.setSearchState({
      foundLocation: false,
      route: { ...route },
      searchTerm: searchStore.searchParams.q,
    });

    const isCitySearch = currentRouteName === RBN_CITY_SEARCH;
    const isCountrySearch = currentRouteName === RBN_COUNTRY_SEARCH;
    const isFederalState = currentRouteName === RBN_FEDERAL_STATE_SEARCH;
    const isRegionSearch = currentRouteName === RBN_REGION_SEARCH;

    const isSearchPage = [
      isCitySearch,
      isCountrySearch,
      isFederalState,
      isRegionSearch,
    ].includes(true);

    // if (currentQuery.adults) {
    //   currentQuery.adults = parseInt(currentQuery.adults);
    // }

    // if (currentQuery.flex) {
    //   currentQuery.flex = parseInt(currentQuery.flex);
    // }

    if (!currentQuery.q) {
      if (isRegionSearch && routeParams.regionSlug) {
        currentQuery.q = getParamsValue(routeParams.regionSlug) ?? null;
      }
      if (isCountrySearch && routeParams.countrySlug) {
        currentQuery.q = getParamsValue(routeParams.countrySlug) ?? null;
      }
      if (isCitySearch && routeParams.citySlug) {
        currentQuery.q = getParamsValue(routeParams.citySlug) ?? null;
      }
      if (isFederalState && routeParams.federalStateSlug) {
        currentQuery.q = getParamsValue(routeParams.federalStateSlug) ?? null;
      }
    }

    const newQuery = mergeSearchQueries(currentQuery, searchStore.searchParams);

    // delete params which are null or `all` (acco_type) or an empty array
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    Object.keys(newQuery).forEach(key => (!newQuery[key] || newQuery[key] === 'all' || (Array.isArray(newQuery[key]) && !newQuery[key].length)) && delete newQuery[key]);

    // if no until date, take same from date + 1
    if (newQuery.from && !newQuery.until) {
      newQuery.until = dayjs(newQuery.from).add(1, 'day').format('YYYY-MM-DD');
    }

    if (!newQuery.from || !newQuery.until) {
      delete newQuery.only_availabilities;
    }

    if (newQuery.zl && !newQuery.area) {
      delete newQuery.zl;
    }

    if (newQuery.offset) {
      delete newQuery.offset;
    }

    if (!selectedSuggestion.value) {
      // used did not select result from suggestion

      if ((newQuery.q && newQuery.q.length > 0)) {
        if ((newQuery.q.toLowerCase() !== searchStore.result?.meta?.location?.name?.toLowerCase()) || !isSearchPage) {
          return searchStore
            .getLocationSearchResults({ q: newQuery.q, lang: locale.value })
            .then((result) => {
              if (!result || !result.type) {
                // for comparison
                if (newQuery.adults && typeof newQuery.adults === 'string') {
                  newQuery.adults = parseInt(newQuery.adults);
                }

                // for comparison
                if (newQuery.flex && typeof newQuery.flex === 'string') {
                  const value = parseInt(newQuery.flex);
                  if (value >= 1 && value <= 3) {
                    newQuery.flex = value as 1 | 2 | 3;
                  }
                }

                if (equals(currentQuery, newQuery)) {
                  return;
                }

                const name = currentRouteName === RBN_SEARCH_MAP ? RBN_SEARCH_MAP : RBN_SEARCH;
                return navigateTo(localePath({ name, query: newQuery }));
              } else {
                routeStore.setSearchState({
                  route,
                  searchTerm: query.q,
                  foundLocation: true,
                });

                delete newQuery.q;

                if (result.type === 'region') {
                  goToSearchRegion(currentRouteName, getSlug(result), newQuery);
                } else if (result.type === 'city') {
                  goToSearchCity(currentRouteName, getSlug(result), newQuery);
                } else {
                  goToSearchCountryOrFederalState(currentRouteName, getSlug(result), newQuery);
                }
              }
            })
            .catch(async () => {
              return navigateTo(localePath({ name: RBN_SEARCH, query: newQuery }));
            });
        } else {
          delete newQuery.q;
          return navigateTo(localePath({ name, query: newQuery }));
        }
      } else {
        const name = currentRouteName === RBN_SEARCH_MAP ? RBN_SEARCH_MAP : RBN_SEARCH;

        if (currentQuery.q && !newQuery.q) {
          delete newQuery.offset;
        }

        if (equals(currentQuery, newQuery)) {
          return;
        }

        return navigateTo(localePath({ name, query: newQuery }));
      }
    } else {
      // user DID select result from suggestion
      delete newQuery.q;
      delete newQuery.area;
      delete newQuery.zl;

      // region
      if (selectedSuggestion.value.type === 'region') {
        goToSearchRegion(currentRouteName, getSlug(selectedSuggestion.value), newQuery);
        return;
      }

      if (selectedSuggestion.value.type === 'city') {
        goToSearchCity(currentRouteName, getSlug(selectedSuggestion.value), newQuery);
        return;
      }

      // federal_state or country
      if (selectedSuggestion.value.type === 'federal_state' || selectedSuggestion.value.type === 'country') {
        goToSearchCountryOrFederalState(currentRouteName, getSlug(selectedSuggestion.value), newQuery);
        return;
      }

      // campsite
      if (!selectedSuggestion.value.type) {
        return navigateTo(
          localePath({
            name: RBN_CAMPSITE_DETAIL,
            params: { identifier: `${selectedSuggestion.value.slug}` },
            query: newQuery,
          }),
        );
      }
    }
  }

  return {
    ages,
    childrenCount,
    clearInput,
    clearedMapSection,
    closeOpenType,
    datePickerValues,
    dateTouched,
    getChildAge,
    hasCampsite,
    hasLocation,
    i18nPromoCampsitesTitle,
    i18nSuggestionTitle,
    isAreaSearch,
    jumpDate,
    localChildrenCount,
    onAccommodationChange,
    onAccommodationSave,
    onCampingCardChange,
    onChildAgeChange,
    onDateChange,
    onDateClear,
    onDateSave,
    onFlexChange,
    onLocationChange,
    onLocationClear,
    onLocationInputFocus,
    onLocationSave,
    onLocationSelected,
    onPersonCountChange,
    onPressedEnter,
    onResultHighlightChange,
    onSave,
    onSearchSubmit,
    openOpenType,
    pointer,
    pointerBackward,
    pointerForward,
    searchHasChanged,
    showSuggestions,
  };
}
